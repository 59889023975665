<template lang="pug">
    div.flex.horizontal-expand.horizontal-centralize.vertical-centralize(style="min-height:90vh;")
        div.page-bg.flex.horizontal-expand.expand-vh-vertical(style="min-height: 100vh; display: flex; width: 100%; justify-content: center;")
          v-flex.pa-3(xs12 md3 v-show="passo===1")
              div.expande-horizontal.centraliza.column
                div.flex.expande-horizontal
                  v-btn(text @click="$router.go(-1)" class="elevation-0" rounded outlined color="white")
                    v-icon(size="13" :color="$theme.primary") mdi-chevron-left
                    span.white--text.fonte.text-capitalize.white--text Login
                v-flex(xs12).mt-12
                  div.expande-horizontal.centraliza
                    //- img( height="30" :src="$theme.logo")
                v-flex(xs12).mt-12.pa-6
                  div.expande-horizontal.centraliza.column
                    span.fonte.text-center.white--text.white--text Bem Vindo(a).
                    
                    v-list(dark dense rounded nav width="270")
                        v-list-item
                            v-avatar.mr-1(color="transparent" classe="elevation-1")
                                v-icon() mdi-account-circle
                            v-list-item-content
                                v-list-item-title(class="fonte")
                                    h2.fonte.font-weight-light {{ getSponsor.name }}
                                v-list-item-subtitle 
                                    span.fonte.grey--text Indicação
                            v-list-item-action
                                v-icon(color="green accent-3") mdi-check

                    v-btn.fonte.mt-12(@click="passo=2" color="green" rounded)
                      span.fonte-bold.white--text Continuar Cadastro
                      v-icon(size="18" color="white") mdi-chevron-right 
          v-flex.pa-3(xs12 md3 v-show="passo===2")
              v-form(ref="form")
                  div.flex.horizontal-expand.centraliza(style="min-height: 100vh; align-items: center;")
                      //- div.flex.expande-horizontal.centraliza
                        //- img(height="120" :src="$theme.logo")
                      div.expande-horizontal.centraliza
                        span(style="font-size: 27pt;").margin-vertical.fonte-bold.pr-6.pl-0.white--text Cadastro
                        v-spacer
                        v-btn(dark @click="$router.go(-1)" class="elevation-1" color="green")
                          v-icon mdi-close
                      div.flex.horizontal-expand.column.mt-6
                          v-flex(xs12)
                              span.fonte.white--text Nome
                          v-flex(xs12)
                              v-text-field(
                                  dense
                                  dark
                                  v-model="payload.name"
                                  placeholder="ex: Marinaldo"
                                  :rules="[v => !!v || 'Preencha aqui']"
                                  :color="$theme.primary"
                                  solo-inverted
                                  flat
                              )
                      
                      //- div.flex.horizontal-expand.column
                      //-     v-flex(xs12)
                      //-         span.fonte.white--text Nickname (usado para login e indicações)
                      //-     v-flex(xs12)
                      //-         v-text-field(
                      //-             dense
                      //-             dark
                      //-             v-mask="['XXXXXXXXXXXXXXXXX']"
                      //-             v-model="payload.nickname"
                      //-             placeholder="ex: naldo"
                      //-             :rules="[v => !!v || 'Preencha aqui']"
                      //-             :color="$theme.primary"
                      //-             solo-inverted
                      //-             flat
                      //-         )
                      
                      div.flex.horizontal-expand.column
                          v-flex(xs12)
                              span.fonte.white--text E-mail
                          v-flex(xs12)
                              v-text-field(
                                  dense
                                  dark
                                  v-model="payload.email"
                                  placeholder="ex: meu_melhor_email@gmail.com"
                                  :rules="[v => !!v || 'Preencha aqui']"
                                  :color="$theme.primary"
                                  solo-inverted
                                  flat
                              )
                      
                      
                      div.flex.horizontal-expand.column
                          v-flex(xs12)
                              span.fonte.white--text CPF
                          v-flex(xs12)
                              v-text-field(
                                  dense
                                  dark
                                  v-model="payload.cpf"
                                  placeholder="ex: 000.000.000-00"
                                  :rules="[v => !!v || 'Preencha aqui']"
                                  v-mask="['###.###.###-##']"
                                  :color="$theme.primary"
                                  solo-inverted
                                  flat
                              )
                      
                      div.flex.horizontal-expand.column
                          v-flex(xs12)
                              span.fonte.white--text Um celular para contato
                          v-flex(xs12)
                              v-text-field(
                                  dense
                                  dark
                                  v-model="payload.phone"
                                  placeholder="ex: (96) 98113-1234"
                                  v-mask="['(##) # #### ####']"
                                  :color="$theme.primary"
                                  solo-inverted
                                  flat
                              )
                      
                      //- div.flex.horizontal-expand.column
                      //-     v-flex(xs12)
                      //-         span.fonte.white--text Senha para transações
                      //-     v-flex(xs12)
                      //-         v-text-field(
                      //-             dense
                      //-             dark
                      //-             v-model="payload.transaction_password"
                      //-             :rules="[v => !!v || 'Preencha este campo']"
                      //-             placeholder="****"
                      //-             v-mask="['####']"
                      //-             type="password"
                      //-             :color="$theme.primary"
                      //-             solo-inverted
                      //-             flat
                      //-         )
                      
                      div.flex.horizontal-expand.column
                          v-flex(xs12)
                              span.fonte.white--text Senha para login
                          v-flex(xs12)
                              v-text-field(
                                  dense
                                  dark
                                  v-model="payload.password"
                                  :rules="[v => !!v || 'Preencha este campo']"
                                  placeholder="******"
                                  type="password"
                                  :color="$theme.primary"
                                  solo-inverted
                                  flat
                              )
                      div.flex.horizontal-expand.column
                          v-flex(xs12)
                              span.fonte.white--text Confirme a Senha para login
                          v-flex(xs12)
                              v-text-field(
                                  dense
                                  dark
                                  v-model="confirm_password"
                                  :rules="[v => !!v || 'Preencha aqui']"
                                  placeholder="******"
                                  type="password"
                                  :color="$theme.primary"
                                  solo-inverted
                                  flat
                              )
                      

                      div.mb-6.expande-horizontal.centraliza
                        v-flex(xs12)
                          //- v-list(color="transparent" nav class="px-0")
                          //-   v-list-item.px-0
                          //-     v-avatar.mr-2(color="#f2f2f2")
                          //-       v-icon mdi-certificate-outline
                          //-     v-list-item-content
                          //-       v-list-item-title
                          //-         a.fonte.white--text(target="_blank" href="" style="text-decoration: none;") Termos de Uso.
                          //-       v-list-item-subtitle 
                          //-         span.fonte.grey--text Aceite os termos de uso ao lado
                          //-     v-list-item-action 
                          //-       v-switch(class="pa-2" dark :color="$theme.primary" outlined v-model="termos")

                      //- div.mb-6.expande-horizontal.centraliza
                      //-     v-btn.fonte(
                      //-       :disabled="senhaOk && termos ? false : true"
                      //-       rounded
                      //-       @click="startCamera"
                      //-       :color="$theme.primary"
                      //-     ) 
                      //-       span.fonte Avançar

                      div.mb-6.expande-horizontal.centraliza
                          v-btn.fonte(
                            :disabled="senhaOk && termos ? false : true"
                            @click="valida_form"
                            color="green"
                          ) 
                            span.fonte-bold.white--text Cadastrar
          v-flex.pa-3(xs12 md3 v-show="passo===3")
            div.mb-6.expande-horizontal.centraliza
              Camera(
                ref="camera" 
                v-model="payload.selfie"
                :path="`documents/selfie/${payload.cpf}`"
              )
            div.mb-6.expande-horizontal.centraliza
              v-btn.fonte(
                :disabled="senhaOk && termos ? false : true"
                rounded
                v-if="payload.selfie"
                @click="goToDocumentsStep"
                :color="$theme.primary"
              ) 
                span.fonte Avançar
          v-flex.pa-3(xs12 v-show="passo===4")
            div.mb-6.expande-horizontal.centraliza.column
              div.expande-horizontal.centraliza
                span(style="font-size: 27pt;").margin-vertical.fonte-bold.pr-6.pl-0.white--text Documentos
              document-files(
                v-model="payload.documents"
                @input="documentsOk($event)"
                :items="documents_requireds"
                :basePath="`documents/user/${payload.email}`"
              )
            div.mb-6.expande-horizontal.centraliza
              v-btn.fonte(
                :disabled="senhaOk && termos ? false : true"
                v-if="documents_ok"
                @click="valida_form"
                color="green"
              ) 
                span.fonte-bold.white--text Concluir cadastro

                      
                      //- div.mb-6.flex.horizontal-expand.column
                      //-     v-btn.fonte(
                      //-         @click="$router.go(-1)"
                      //-         x-small
                      //-         text
                      //-         block
                      //-         dark
                      //-         :color="$theme.primary"
                      //-     )
                      //-       span.white--text Já tem cadastro? Faça Login


</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Camera from './Camera.vue'
import DocumentFiles from './DocumentFiles.vue'
export default {
  props: ["sponsor_id"],
  components: {
    Camera,
    DocumentFiles
  },
  data() {
    return {
      passo: 1,
      modal: false,
      termos: true,
      confirm_password: "",
      // documents_requireds: [
      //   {
      //     name: 'Ato constitutivo ou Contrato Social',
      //     required: true
      //   },
      //   {
      //     name: 'Cartão CNPJ',
      //     required: true
      //   },
      //   {
      //     name: 'Comprovante de endereço da Empresa',
      //     required: true
      //   }
      // ],
      documents_requireds: [
        {
          name: 'Habilitação ou RG',
          required: true
        },
        {
          name: 'Comprovante de endereço',
          required: true
        },
      ],
      payload: {
        cpf: "",
        documents: [],
        transaction_password: "",
        password: "",
        sponsor: {
          _id: "nosponsor"
        }
      },
      documents_ok: false,
      senhaOk: false
    };
  },
  computed: {
    ...mapGetters(["getSponsor"])
  },
  watch: {
    confirm_password(val) {
      if (val === this.payload.password) {
        this.senhaOk = true;
      } else {
        this.senhaOk = false;
      }
    }
  },
  methods: {
    ...mapActions(["createUser", "verifySponsor"]),
    documentsOk(docs) {
      if (docs.length == this.documents_requireds.length) {
        this.documents_ok = true;
      }
    },
    startCamera() {
      this.passo = 3;
      setTimeout(async () => {
        await this.$refs.camera.startCamera();
      }, 300);
    },
    goToDocumentsStep() {
      this.passo = 4;
    },
    valida_form() {
      if (this.$refs.form.validate()) {
        this.createUser(this.payload);
      }
    }
  },
  async created() {
    if (this.sponsor_id === "nosponsor") {
      this.passo = 2;
      this.payload.sponsor_id = "nosponsor";
    } else {
      this.payload.sponsor = await this.verifySponsor(this.sponsor_id);
      this.payload.sponsor_id = this.sponsor_id;
    }
  }
};
</script>

<style>
.page-bg {
  background: #102a2a;
}
</style>