<template>
  <div class="camera-capture">
    <div class="expande-horizontal">
      <h3 style="font-size: 27pt;" class="fonte-bold white--text">Selfie</h3>
    </div>
    <video
      v-if="!value"
      style="border-radius: 6px;"
      ref="video"
      width="320"
      height="240"
      autoplay
      :playsInline="true"
    ></video>
    <canvas
      style="border-radius: 6px;"
      ref="canvas"
      width="320"
      height="240"
    ></canvas>
    <v-btn class="mt-3" @click="capturePhoto" v-if="!value">Capturar</v-btn>
    <v-btn class="mt-3" @click="resetPhoto" v-else>Tentar Novamente</v-btn>
  </div>
</template>

<script>
import {
  getStorage,
  ref,
  getDownloadURL,
  uploadBytesResumable,
  deleteObject
} from "firebase/storage";
export default {
  props: ["value", "path"],
  name: "CameraCapture",
  methods: {
    resetPhoto() {
      this.$emit("input", null);
      setTimeout(() => {
        this.startCamera();
      }, 300);
    },
    async startCamera() {
      try {
        const stream = await navigator.mediaDevices.getUserMedia({
          video: true
        });
        this.$refs.video.srcObject = stream;
      } catch (error) {
        console.error("Erro ao acessar a câmera do dispositivo:", error);
      }
    },
    capturePhoto() {
      const self = this;
      const context = this.$refs.canvas.getContext("2d");
      const canvas = this.$refs.canvas;
      context.drawImage(
        this.$refs.video,
        0,
        0,
        this.$refs.canvas.width,
        this.$refs.canvas.height
      );
      canvas.toBlob(async blob => {
        var metadata = {
          contentType: "image/png"
        };

        const self = this;

        const storage = getStorage();
        const storageRef = ref(storage);
        const imagesRef = ref(
          storageRef,
          `${self.path}/selfie.png`
        );
        const taskLoad = uploadBytesResumable(imagesRef, blob, metadata);
        taskLoad.on(
          "state_changed",
          //get progress
          snapshot => {
            self.loading =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
          },
          error => {
            alert('Erro ao fazer upload, tente novamente.');
            self.resetPhoto();
          },
          //success
          () => {
            getDownloadURL(taskLoad.snapshot.ref).then(link => {
              self.$emit("input", link);
              self.loading = 0;
              // setTimeout(() => {
              //   // reiniciar camera
              // }, 1000);
            });
          }
        );
        
      });
    }
  }
};
</script>

<style>
.camera-capture {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  border-radius: 6px;
  padding: 10px;
}
</style>
